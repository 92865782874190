import React, {useEffect, useState} from "react";
import { useParams } from "react-router";
import Moment from 'react-moment';
import 'moment-timezone';
import {Helmet} from "react-helmet";


function clearAvis () {
    var n = 0;
    for (let step = 0; step < 20; step++)
    {
        try {
            document.getElementById("Avis"+step).style.display = ("none");
        }
        catch (e) {

        }
    }
}

function Pagination() {
    clearAvis();
    var n=0;
    for (let step = n*6; step < n*6+6 ; step++)
    {
        try {
            document.getElementById("Avis"+step).style.display = ("block");
        }
        catch (e) {

        }
    }
}

function Pagination1() {
    clearAvis();
    var n=1;
    for (let step = n*6; step < n*6+6 ; step++)
    {
        document.getElementById("Avis"+step).style.display = ("block");
    }
}


function Pagination2() {
    clearAvis();
    var n=2;
    for (let step = n*6; step < n*6+6 ; step++)
    {
        document.getElementById("Avis"+step).style.display = ("block");
    }
}



function Ville() {
    const [data,setData]=useState({"nom":"", "texte": "", "departement":"", "code":"0"});
    const [dataAvis,setDataAvis]=useState({"records":[]});
    const [longueur, setLongueur] = useState(1);
    useEffect(()=>{
        function getData(){
            fetch(filename
                ,{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function(response){
                    return response.json();

                })

                .then(function(myJson) {
                    setData(myJson)
                });
        }

        function getDataAvis(donnees){
            fetch('https://api.airtable.com/v0/app7WeJBnEGn8iRoK/Avis?api_key=keyx0h0Igb7rUsPEi&filterByFormula=AND(%7BValide%7D%3D%22Valide%22%2C+%7BPage%7D+%3D%22'+tablename+'%22)&sort[0][field]=Date&sort[0][direction]=desc')
                .then((resp) => resp.json())
                .then(data => {
                    if (data.records.length > 0){
                        setDataAvis({ records: data.records });
                        setLongueur (data.records.length);
                    }
                    else {
                        if (donnees.departement === "Paris") {
                            if (donnees.code_postal === "75001") {
                                var avisGenerique = [{
                                    fields: {
                                        Note: 5,
                                        Date: "2022/05/11",
                                        Avis: "Je recommande cette plateforme de mise en relation avec des professionnels du dépannage à domicile à "+ donnees.departement + " " + donnees.code_postal.replace("7500", "").replace("750", "") + "er-Arrondissement" + " (" + donnees.code_postal + ") dans de nombreux métiers de services d'urgences 24H/24 et 7J/7. Cela est très pratique de pouvoir trouver des Serruriers, Plombiers, Electriciens, Vitriers, Chauffagistes et pleins d'autres artisans honnêtes rapides et efficaces dans toutes les villes d'Ile-de-France",
                                        Nom: "Bastien Gerard"
                                    }
                                }]
                            }
                            else {
                                avisGenerique = [{
                                    fields: {
                                        Note: 5,
                                        Date: "2022/05/11",
                                        Avis: "Je recommande cette plateforme de mise en relation avec des professionnels du dépannage à domicile à "+ donnees.departement + " " + donnees.code_postal.replace("7500", "").replace("750", "") + "eme-Arrondissement" + " (" + donnees.code_postal + ") dans de nombreux métiers de services d'urgences 24H/24 et 7J/7. Cela est très pratique de pouvoir trouver des Serruriers, Plombiers, Electriciens, Vitriers, Chauffagistes et pleins d'autres artisans honnêtes rapides et efficaces dans toutes les villes d'Ile-de-France",
                                        Nom: "Bastien Gerard"
                                    }
                                }]
                            }
                        }
                        else {
                            avisGenerique = [{
                                fields: {
                                    Note: 5,
                                    Date: "2022/05/11",
                                    Avis: "Je recommande cette plateforme de mise en relation avec des professionnels du dépannage à domicile à "+ donnees.nom + " (" + donnees.code_postal + ") dans de nombreux métiers de services d'urgences 24H/24 et 7J/7. Cela est très pratique de pouvoir trouver des Serruriers, Plombiers, Electriciens, Vitriers, Chauffagistes et pleins d'autres artisans honnêtes rapides et efficaces dans toutes les villes d'Ile-de-France",
                                    Nom: "Bastien Gerard"
                                }
                            }]
                        }
                        setDataAvis({records: avisGenerique});
                    }
                }).catch(err => {
            });
        }

        getData();
        getDataAvis(data);

    },[data]); // eslint-disable-line react-hooks/exhaustive-deps

    let { dep, ville } = useParams();
    var filename = '/json/'+ dep+ '/'+ ville+ '/data.json';
    var title =  "Les meilleurs dépanneurs d'urgence à domicile sur " + data.nom+ " (" + data.code_postal +")";
    var description = "Les meilleurs dépanneurs serruriers, vitriers, plombiers, chauffagistes, climatisation, électroménager, vidéosurveillance, alarme et bien d'autres activités sur " + data.nom+ " (" + data.code_postal +")";
    var tablename = ville+'/';

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                          "@type": "Locksmith",
                          "name": "Serrurier ${data.nom} (${data.code_postal})",
                            "image": [
                                    "https://adcentreprise.fr/assets/img/logo-adc-entreprise.webp"
                                   ],
                                "telephone": "0972519300",
                                "address": {
                                        "@type": "PostalAddress",
                                        "streetAddress": "ADC Entreprise",
                                        "addressLocality": "${data.nom}",
                                        "addressRegion": "${data.departement}",
                                        "postalCode": "${data.code_postal}",
                                        "addressCountry": "FR"
                                      },
                          "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "5",
                            "reviewCount": "${longueur}"
                          }
                        }
                      `}
  </script>
            </Helmet>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="/">Accueil / </a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href={"/"+dep+"/"}>Dépanneurs {data.departement} / </a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="#">Dépanneurs {data.nom} / </a>
                    </li>
                </ul>
            </div>

            <section className="breadcrumb_main_area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="breadcrumb_main nice_title">
                            <img src="/assets/img/logo-adc-entreprise.webp" className="image-logo hidden-xs" alt="bonhomme"/>
                            <h1>Dépanneurs à {data.nom} ({data.code_postal})</h1>
                            <span>A domicile pour résoudre tous vos problèmes</span>
                            <div className="row row-bouton">
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a target="_blank" href={"https://forms.adcentreprise.fr/devis?nompage="+data.nom}><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="another_facitilies_area">
                <div className="container">
                    <div className="section_title nice_title content-center">
                        <h3>Nos services</h3>
                    </div>
                    <div className="facilities_top_para">
                        <p style={{"margin" :"15px"}}>
                            {data.texte}
                        </p>
                    </div>
                </div>
            </section>

            <section className="select_room_area ">
                <div className="container">
                    <div className="row">
                        <div className="select_room clearfix">
                            <div className="section_title nice_title content-center">
                                <h3>Catégorie de Métiers à {data.nom}</h3>
                            </div>
                            <div className="facilities_top_para text-center">
                                <p style={{"margin" :"15px"}}>
                                    Ci-dessous vous sont présentés les catégories de métiers représentés par les meilleurs entreprises de dépannages d'urgences à domicile à {data.nom} ({data.code_postal}) pour votre plus grand confort. Vous trouverez toutes les activitées dont vous pourriez avoir besoin pour être dépanner à la maison rapidement et efficacement par de véritables professionnels des métiers suivants. La liste ci-dessous va augmenter pour vous offrir un repertoire de métiers toujours plus fourni et ainsi vous mettre à disposition une solution complète pour vous dépanner à la maison.
                                </p>
                            </div>

                            <div className="select_room_content clearfix">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <a href="serrurier/"><img src="/assets/img/categorie/serrurerie.webp" alt=""/></a>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <a href="serrurier/"><h5>Serrurier</h5></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/categorie/vitrerie.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room text-center">
                                                <div className="room_quality">
                                                    <h5>Vitrier</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="room_thumb small_room_thumb">
                                        <img src="/assets/img/categorie/electricien.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Electricien</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/categorie/video-surveillance.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Vidéo Surveillance</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/categorie/alarme.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Alarme</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="room_thumb small_room_thumb">
                                        <img src="/assets/img/categorie/electromenager.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Electromenager</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/categorie/plomberie.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Plombier</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/categorie/chauffagiste.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Chauffagiste</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="room_thumb small_room_thumb">
                                        <img src="/assets/img/categorie/desinsectisation.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Désinsectisation</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/categorie/climatisation.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Climatisation</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/categorie/peintre-en-batiment.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Peintre en batiment</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/categorie/deratisation.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Dératisation</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about_us_area">
                <div className="container">
                    <div className="about_us clearfix">
                        <div className="section_title nice_title content-center">
                            <h3 style={{"marginBottom":"20px"}}>Comment nous contacter pour une intervention à {data.nom} ({data.code_postal})? </h3>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="showcase">
                            <div className="section_title margin-bottom-50 text-center">
                                <i className="numero">1</i>
                                <h5><strong>Contactez-nous par téléphone </strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details">
                                        <img src="/assets/img/comment-1.webp" alt="Comment-1"/>
                                    </div>
                                    <div>
                                        <p className="texte-comment">
                                            Vous pouvez nous téléphoner 24h/24 et 7j/7 pour demander une intervention en urgence.
                                            Si ce n'est pas une urgence, merci de nous contacter entre 09h00 et 17h00.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="showcase">
                            <div className="section_title margin-bottom-50 text-center">
                                <i className="numero">2</i>
                                <h5><strong>Devis gratuit, demandez notre tarif</strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details">
                                        <img src="/assets/img/comment-2.webp" alt="comment-2"/>
                                    </div>
                                    <div>
                                        <p className="texte-comment">
                                            Intervention avec un prix fixe annoncé par téléphone pour éviter toutes
                                            mauvaises surpprises !
                                            Pour une demande particulière, un devis gratuit vous sera proposé.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="showcase">
                            <div className="section_title margin-bottom-50 text-center">
                                <i className="numero">3</i>
                                <h5><strong>Intervention rapide dans l'heure</strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details">
                                        <img src="/assets/img/comment-3.webp" alt="comment-3"/>
                                    </div>
                                    <div>
                                        <p className="texte-comment">Un dépanneur qualifié et expérimenté vous sera attribué
                                            pour un dépannage à domicile en moins de 1 heure !
                                            Notre artisan sera noté par vos soins sur sa prestation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-bouton">
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <a target="_blank" href="https://forms.adcentreprise.fr/devis"><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about_us_area" itemScope itemType="https://schema.org/FAQPage">
                <div className="container">
                    <div className="about_us clearfix">
                        <div className="section_title nice_title content-center">
                            <h3>Foire aux questions</h3>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <div className="showcase"
                                 style={{'backgroundColor' : '#efe8b4', 'padding': '10px', 'borderRadius': '15px'}}>
                                <div className="section_title margin-bottom-50">
                                    <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>Je recherche un Plombier qui intervient
                                        sur le département des Hauts-de-Seine, est ce possible d'avoir plusieurs devis
                                        sur cette plateforme de mise en relation avec des professionnels de la Plomberie
                                        ?</strong></h5>
                                </div>
                                <div className="section_description">
                                    <div className="about_hotel">
                                        <div className="about_details" itemScope itemProp="acceptedAnswer"
                                             itemType="https://schema.org/Answer">
                                            <p itemProp="text">
                                            Oui biensur, il vous suffit de contacter la plateforme pour demander à
                                                être mis en contact avec plusieurs entreprises de Plomberies sur votre
                                                commune et ainsi nous vous souhaitons de trouver l'entreprise de
                                                dépannage qui vous offrira le meilleur rapport qualité prix sur le
                                                marché des artisans Plombiers dépanneurs à domicile.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <div className="showcase"
                                 style={{'backgroundColor' : '#ecccdb', 'padding': '10px', 'borderRadius': '15px'}}>
                                <div className="section_title margin-bottom-50">
                                    <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>Je suis satisfait ou insatisfait d'un
                                        dépannage à la suite du passage d'une entreprise à mon domicile et je voudrais
                                        laisser un commentaire, est ce possible ? </strong></h5>
                                </div>
                                <div className="section_description">
                                    <div className="about_hotel">
                                        <div className="about_details" itemScope itemProp="acceptedAnswer"
                                             itemType="https://schema.org/Answer">
                                            <p itemProp="text">
                                            Oui tout à fait cela est possible et nous vous incitons à laisser un
                                                commentaire positif ou négatif à fin que nous puissions constament
                                                améliorer notre liste de professionnels grâce à votre expérience
                                                personnelle.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <div className="showcase"
                                 style={{'backgroundColor' : '#b2d6ee', 'padding': '10px', 'borderRadius': '15px'}}>
                                <div className="section_title margin-bottom-50">
                                    <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>Le devis d'une entreprise selectionner sur
                                        cette plateforme de services à domicile ne me convient pas, puis je le refuser
                                        et en demander un nouveau qui pourrait être plus compétitif ?</strong></h5>
                                </div>
                                <div className="section_description">
                                    <div className="about_hotel">
                                        <div className="about_details" itemScope itemProp="acceptedAnswer"
                                             itemType="https://schema.org/Answer">
                                            <p itemProp="text">
                                            Oui bien entendu, nous travaillons à ce sujet pour que la compétition
                                                tarifaire soit la meilleur possible et la plus discount pour qu'elle
                                                soit accessible à toutes les bourses selon vos besoins précis.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <div className="showcase"
                                 style={{'backgroundColor' : '#ddf7dc', 'padding': '10px', 'borderRadius': '15px'}}>
                                <div className="section_title margin-bottom-50">
                                    <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>Le dépannage ou les travaux qui ont été
                                        réalisés à mon domicile ne me convienne pas, quel sont mes recours ?</strong>
                                    </h5>
                                </div>
                                <div className="section_description">
                                    <div className="about_hotel">
                                        <div className="about_details" itemScope itemProp="acceptedAnswer"
                                             itemType="https://schema.org/Answer">
                                            <p itemProp="text">
                                            (1) Vous pouvez en informer la plateforme dans un premier temps pour
                                                qu'une enquête sur la dite entreprise puisse être ouverte. (2) Vous
                                                pouvez publier un commentaire exposant l'entreprise à corriger son
                                                erreur pour vous satisfaire. (12) Vous pouvez aussi contacter
                                                l'entreprise qui est intervenu via son numéro de téléphone inscrit sur
                                                votre devis ou facture pour trouver une solution et un arrangement à
                                                l'amiable.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-bouton hidden-xs">
                <a href="https://wa.link/h0r9kh" target="_blank" rel="noreferrer"><img src="/assets/img/img-whatsapp.webp" alt="Whatsapp" />
                </a>
            </section>
            <section className="container-bouton visible-xs">
                <a href="https://wa.link/h0r9kh" target="_blank" rel="noreferrer"><img src="/assets/img/adc-whatsapp.webp" alt="Whatsapp" />
                </a>
            </section>

            <section className="review">
                <div className="section_content">
                    <div className="showcase">
                        <div className="section_description container">
                            <div className="row" style={{"marginRight":"0px", "marginLeft":"0px"}}>
                                <div className="facilities_name clearfix margin-bottom-40 ">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="section_title nice_title content-center">
                                            <h3>AVIS</h3>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button data-page={tablename} data-nompage={data.nom} data-tally-open="3yE7X3" data-tally-layout="modal" data-tally-align-left="1" data-tally-emoji-text="👋" data-tally-emoji-animation="wave" className="btn btn-primary" data-tally-width="600">Donner votre avis</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="testimonials-grid">
                    { dataAvis.records.map((avis, indexAvis) => (

                        <div className="testimonials-box" id={"Avis"+indexAvis}>
                            <div className="inside">
                                <div className="testimonials-box-title"> <Moment format="DD/MM/YYYY" parse="YYYY-MM-DD">{avis.fields.Date} </Moment></div>
                                <div className="etoile">{
                                    [...Array(avis.fields.Note)].map((elementInArray, index) => (
                                        <i className="fa fa-star"></i>))
                                }
                                </div>
                                <div className="testimonials-box-text"> {avis.fields.Avis}</div>
                                <div
                                    className="testimonials-box-username"> {avis.fields.Nom}
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
                <div className="pagination">
                    <button className="btn btn-primary" onClick={Pagination}>1</button>
                    {dataAvis.records.length>6 ? <button className="btn btn-primary boutonpage  " onClick={Pagination1}>2</button> : <button className="vide"></button>}
                    {dataAvis.records.length>12 ? <button className="btn btn-primary boutonpage  " onClick={Pagination2}>3</button> : <button className="vide"></button>}
                </div>
            </div>



            <div className="contact_us_area content-left">
                <div className="container">
                    <div className="contact_us clearfix">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="call clearfix">
                                <h6>Téléphone</h6>
                                <p><a href="tel:0972519300">09.72.51.93.00</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="email_us clearfix">
                                <h6>Email</h6>
                                <p>contact@adcentreprise.fr</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="social_icons clearfix">
                                <ul>
                                    <li><a href="https://www.facebook.com/ADCentrerpise"><i className="fa fa-facebook"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer_area">
                <div className="container">
                    <div className="footer">
                        <div className="row">
                            <div className="container">
                                <div className="footer_copyright margin-tb-30 content-center hidden-xs">
                                    <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <a href="/mentions-legales/">Mentions
                                    légales</a> - <a href="/condition-d-utilisation/">Condition d'utilisation</a> - <a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                                </div>
                                <div className="footer_copyright margin-tb-30 content-center visible-xs">
                                    <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <br /><a href="/mentions-legales/">Mentions
                                    légales</a> <br /><a href="/condition-d-utilisation/">Condition d'utilisation</a> <br /><a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default Ville;