import React from "react";

function Mention() {
    return (
        <div>
            <section className="breadcrumb_main_area">
                <div>
                    <div className="row">
                        <div className="breadcrumb_main nice_title">
                            <img loading="lazy"  src="/assets/img/logo-adc-entreprise.webp" className="image-logo hidden-xs hidden-sm" alt="bonhomme"/>
                            <h1>Les meilleurs dépanneurs</h1>
                            <span>A domicile pour résoudre tous vos problèmes</span>
                            <div className="row row-bouton row-bouton-haut">
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a target="_blank" href="https://forms.adcentreprise.fr/devis?nompage=Accueil" ><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="another_facitilies_area">
                <div className="container">
                    <div className="facilities_top_para">
                        <div className="section_title nice_title content-center">
                            <h3>Mentions légales du site https://adcentreprise.fr </h3>
                        </div>
                        <p style={{"textAlign":"center", "marginBottom":"30px"}}>
                            Le site https://adcentreprise.fr est un service développé par la société Cyber Web Marketing, SARL au capital de 1 000€ immatriculée auprès du RCS de Paris avec le code NAF 6201Z correspondant au secteur d'activité : Programmation informatique. Numéro de Siret : 832 984 587 00010 domiciliée au 66, Avenue Des Champs Elysées, 75008 Paris. - Code APE : 6201Z - SIRET : 832 984 587 00010 - Hébergeur : OVH, 2 rue Kellermann – 59 100 Roubaix – France
                        </p>


                        <p style={{"textAlign":"center", "marginBottom":"30px"}}>
                            Tous les droits de propriété intellectuelle relatifs au Site (notamment les droits sur les textes, la mise en forme, les graphismes, la structure, les éléments sonores) appartiennent à l’Editeur, à ses donneurs d’ordres et/ou annonceurs, et ce sans aucune limitation. Egalement les icons sur www.iconfinder.com                         </p>
                        <p>
                            N’importe quelle page du site peut potentiellement être référencée dans les moteurs et annuaires de recherche du Web, sous réserve que ne soit pas mentionné une quelconque indications personnelles permettant d’identifier la personne ayant afin d’assurer le secret professionnel.                        </p>
                    </div>
                </div>
            </section>
            <footer className="footer_area">
                <div className="container">
                    <div className="footer">
                        <div className="row">
                            <div className="container">
                                <div className="footer_copyright margin-tb-30 content-center">
                                    <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <a href="/mentions-legales/">Mentions
                                    légales</a> - <a href="/condition-d-utilisation/">Condition d'utilisation</a> - <a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Mention;