import React from "react";

function Politique() {
    return (
        <div>
            <section className="breadcrumb_main_area">
                <div>
                    <div className="row">
                        <div className="breadcrumb_main nice_title">
                            <img loading="lazy"  src="/assets/img/logo-adc-entreprise.webp" className="image-logo hidden-xs hidden-sm" alt="bonhomme"/>
                            <h1>Les meilleurs dépanneurs</h1>
                            <span>A domicile pour résoudre tous vos problèmes</span>
                            <div className="row row-bouton row-bouton-haut">
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a target="_blank" href="https://forms.adcentreprise.fr/devis?nompage=Accueil" ><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="another_facitilies_area">
                <div className="container">
                    <div className="facilities_top_para">
                        <div className="section_title nice_title content-center">
                            <h3>Politiques de confidentialités de https://adcentreprise.fr</h3>
                        </div>

                        <div className="section_title nice_title content-center">
                            <h3>Données personnelles et déclaration informatique et liberté</h3>
                        </div>
                        <p style={{"textAlign":"center"}}>
                            Conformément à l’article 27 de la Loi Informatique et Liberté, Cyber Web Marketing informe les internautes que l’ensemble des informations récupérées par le biais des formulaires présents sur le site sont strictement nécessaires à l’exécution des prestations offertes par les entreprises présentes sur le site. Elles sont collectées dans le but de permettre la mise en relation entre l’internaute et le professionnel auquel il souhaite faire appel. Conformément à la loi Informatique et Libertés du 06/01/1978, l’internaute dispose d’un droit d’accès, de rectification et d’opposition aux informations le concernant qui peut s’exercer par courrier électronique à l’adresse : contact@adcentreprise.fr en indiquant : nom, prénom, adresse et nom de la société.
                        </p>
                        <div className="section_title nice_title content-center">
                            <h3>Propriété intellectuelle</h3>
                        </div>
                        <p style={{"textAlign":"center"}}>
                            Cyber Web Marketing reste titulaire de l’intégralité de ses droits et propriété intellectuelle. Tous les éléments contenus sur le site https://adcentreprise.fr (textes, images animées ou non, et plus généralement tout type de données) sont protégés par le Code de la propriété intellectuelle. Toute représentation et/ou reproduction totale ou partielle de site https://adcentreprise.fr par quelque procédé que ce soit, sans l’autorisation expresse du responsable de la publication est interdite et constituerait une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.
                        </p>
                    </div>
                </div>
            </section>
            <footer className="footer_area">
                <div className="container">
                    <div className="footer">
                        <div className="row">
                            <div className="container">
                                <div className="footer_copyright margin-tb-30 content-center">
                                    <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <a href="/mentions-legales/">Mentions
                                    légales</a> - <a href="/condition-d-utilisation/">Condition d'utilisation</a> - <a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Politique;