import './App.css';
import { BrowserRouter as Router, Route, Routes , Navigate} from "react-router-dom";
import Accueil from "./components/Accueil";
import Categorie from "./components/Categorie";
import Departement from "./components/Departement";
import Ville from "./components/Ville";
import VilleCategorie from "./components/Ville-categorie";
import DepartementCategorie from "./components/Departement-categorie";
import Mention from "./components/Mention";
import Condition from "./components/Condition";
import Politique from "./components/politique";

function App() {
  return (
    <div className="App">
          <div className="main_header_area">
                <div className="mainmenu">
                    <div id="nav">
                        <nav className="navbar navbar-default">
                            <div className="navbar-header">
                                <div className="site_logo fix text-center">
                                    <a id="brand" className="clearfix navbar-brand" href="/"><img
                                        src="/logo.webp"  alt="ADC Entreprise" /></a>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <Router>
                        <Routes>
                            <Route path="/" exact element={ <Accueil /> }/>
                            <Route path="/electricite.html" exact element={<Navigate to="/" />} />
                            <Route path="/mentions-legales/" exact element={ <Mention /> }/>
                            <Route path="/condition-d-utilisation/" exact element={ <Condition /> }/>
                            <Route path="/politique-de-confidentialite/" exact element={ <Politique /> }/>
                            <Route path="/categorie/:id/" element= {<Categorie />} />
                            <Route path="/:id/" element= {<Departement />} />
                            <Route path="/:dep/categorie-:categorie/" element= {<DepartementCategorie />} />
                            <Route path="/:dep/:ville/" element= {<Ville />} />
                            <Route path="/:dep/:ville/:cat/" element= {<VilleCategorie />} />
                        </Routes>
                    </Router>
                </div>
            </div>
    </div>
  );
}

export default App;
