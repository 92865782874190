import React from "react";

function Condition() {
    return (
        <div>
            <section className="breadcrumb_main_area">
                <div>
                    <div className="row">
                        <div className="breadcrumb_main nice_title">
                            <img loading="lazy"  src="/assets/img/logo-adc-entreprise.webp" className="image-logo hidden-xs hidden-sm" alt="bonhomme"/>
                            <h1>Les meilleurs dépanneurs</h1>
                            <span>A domicile pour résoudre tous vos problèmes</span>
                            <div className="row row-bouton row-bouton-haut">
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a target="_blank" href="https://forms.adcentreprise.fr/devis?nompage=Accueil" ><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="another_facitilies_area">
                <div className="container">
                    <div className="facilities_top_para">
                        <div className="section_title nice_title content-center">
                            <h3>Conditions générales d’utilisation de https://adcentreprise.fr</h3>
                        </div>


                        <div className="section_title nice_title content-center">
                            <h3>Champ d’activité et description du service</h3>
                        </div>

                        <p style={{"textAlign":"center", "marginBottom":"30px"}}>
                            Le site https://adcentreprise.fr offre un service de mise en relation avec des professionnels du dépannage à domicile dans la quasi-totalité des travaux du bâtiment et autres urgences à domicile dans l’urgence 7J/7 et 24H/24 sur toute l’ile de France. A travers ce site, l’internaute a la possibilité d’entrer en contact avec une entreprise spécialisée dans le dépannage de la catégorie de métier qu'elle aura sélectionné et ce que ce soit par téléphone ou par voie électronique en remplissant un formulaire de contact. Cyber Web Marketing met tout en œuvre pour offrir à l’internaute un site sécurisé, notamment en matière d’absence de virus. Il appartient cependant à l’internaute de protéger son matériel ou réseau informatique en se dotant d’un anti-virus qui sera régulièrement mis à jour par l’internaute. Cyber Web Marketing ne pourra en aucun cas être tenu pour responsable d’une quelconque intrusion de virus dans le matériel informatique de l’internaute, ni des dommages directs ou indirects pouvant en résulter.
                        </p>

                        <p style={{"textAlign":"center"}}>
                            Propriétaire du site https://adcentreprise.fr, l’entreprise Cyber Web Marketing se dégage de toute responsabilité concernant les échanges et travaux effectués entre l’internaute et l’entreprise spécialisée dans le dépannage. En cas de problème lié à une prestation, un devis ou tout autre élément entre ladite entreprise et l’internaute, il ne sera pas possible pour ce dernier d’engager la responsabilité de Cyber Web Marketing.
                        </p>

                    </div>
                </div>
            </section>
            <footer className="footer_area">
                <div className="container">
                    <div className="footer">
                        <div className="row">
                            <div className="container">
                                <div className="footer_copyright margin-tb-30 content-center">
                                    <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <a href="/mentions-legales/">Mentions
                                    légales</a> - <a href="/condition-d-utilisation/">Condition d'utilisation</a> - <a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Condition;