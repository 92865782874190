import React, {useEffect, useState} from "react";
import { useParams } from "react-router";
import './Ville-Categorie.css'
import Moment from 'react-moment';
import 'moment-timezone';
import {Helmet} from "react-helmet";
import parse from 'html-react-parser'

function clearAvis () {
    var n = 0;
    for (let step = 0; step < 20; step++)
    {
        try {
            document.getElementById("Avis"+step).style.display = ("none");
        }
        catch (e) {

        }
    }
}

function Pagination() {
    clearAvis();
    var n=0;
    for (let step = n*6; step < n*6+6 ; step++)
    {
        try {
            document.getElementById("Avis"+step).style.display = ("block");
        }
        catch (e) {

        }
    }
}

function Pagination1() {
    clearAvis();
    var n=1;
    for (let step = n*6; step < n*6+6 ; step++)
    {
        document.getElementById("Avis"+step).style.display = ("block");
    }
}


function Pagination2() {
    clearAvis();
    var n=2;
    for (let step = n*6; step < n*6+6 ; step++)
    {
        document.getElementById("Avis"+step).style.display = ("block");
    }
}


function VilleCategorie() {
    const [data,setData]=useState({"nom":"", "texte": "", "departement":"", "code":"", "top3":[], "categorie":{}, "texte_categorie": {
        "Serrurier": ""
        }});
    const [dataAvis,setDataAvis]=useState({"records":[]});
    const [longueur, setLongueur] = useState(1);

    useEffect(()=>{
        function getData(){
            fetch(filename
                ,{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function(response){
                    return response.json();

                })

                .then(function(myJson) {
                    setData(myJson);
                });
        }
        function getDataAvis(donnees){
            fetch('https://api.airtable.com/v0/app7WeJBnEGn8iRoK/Avis?api_key=keyx0h0Igb7rUsPEi&filterByFormula=AND(%7BValide%7D%3D%22Valide%22%2C+%7BPage%7D+%3D%22'+tablename+'%22)&sort[0][field]=Date&sort[0][direction]=desc')
                .then((resp) => resp.json())
                .then(data => {
                    if (data.records.length > 0){
                        setDataAvis({ records: data.records });
                        setLongueur (data.records.length);
                    }
                    else {
                        if (donnees.departement === "Paris") {
                            if (donnees.code_postal === "75001") {
                                var avisGenerique = [{
                                    fields: {
                                        Note: 5,
                                        Date: "2022/05/11",
                                        Avis: "Cet artisan serrurier à " + donnees.departement + " " + donnees.code_postal.replace("7500", "").replace("750", "") + "er-Arrondissement" + " (" + donnees.code_postal + ") est sérieux, rapide et très efficace dans sa prestation de dépannage serrurerie. Il pratique des tarifs et prix honnêtes, pas cher et se trouve donc compétitif de la concurrence.",
                                        Nom: "Bastien Gerard"
                                    }
                                }]
                            }
                            else {
                                avisGenerique = [{
                                    fields: {
                                        Note: 5,
                                        Date: "2022/05/11",
                                        Avis: "Cet artisan serrurier à " + donnees.departement + " " + donnees.code_postal.replace("7500", "").replace("750", "") + "eme-Arrondissement" + " (" + donnees.code_postal + ") est sérieux, rapide et très efficace dans sa prestation de dépannage serrurerie. Il pratique des tarifs et prix honnêtes, pas cher et se trouve donc compétitif de la concurrence.",
                                        Nom: "Bastien Gerard"
                                    }
                                }]
                            }
                        }
                        else {
                            avisGenerique = [{fields: { Note: 5, Date: "2022/05/11", Avis: "Cet artisan serrurier à "+ donnees.nom +" (" + donnees.code_postal +") est sérieux, rapide et très efficace dans sa prestation de dépannage serrurerie. Il pratique des tarifs et prix honnêtes, pas cher et se trouve donc compétitif de la concurrence.", Nom: "Bastien Gerard" }}]

                        }

                        setDataAvis({ records: avisGenerique });
                    }
                }).catch(err => {
            });
        }
        getData();
        getDataAvis(data);
    },[data]); // eslint-disable-line react-hooks/exhaustive-deps

    let { dep, ville, cat } = useParams();
    var filename = '/json/'+cat+"/"+ dep+ '/'+ ville+ '/data.json';
    var tablename = ville+'/'+cat;

    if (data.departement == "Paris") {
        if (data.code_postal == "75001"){
            var title =  "Serrurier "+ data.nom +" urgence dépannage serrurerie "+ data.code_postal.replace("7500","").replace("750","") +"er-Arrondissement ("+data.code_postal+")"
        }
        else {
            var title =  "Serrurier "+ data.nom +" urgence dépannage serrurerie "+ data.code_postal.replace("7500","").replace("750","") +"eme-Arrondissement ("+data.code_postal+")"
        }
        if (data.code_postal == "75001"){
            var description = "Serrurier "+ data.nom +" ADC dépannage serrurerie urgence pas cher 24H/24 & 7J/7 dans le " + data.code_postal.replace("7500","").replace("750","") +"er-Arrondissement ("+data.code_postal+") ✓ Ouverture de porte claquée fermée ou bloqué ✓ remplacement canons ✓ Coffre-Fort "
        }
        else {
            var description = "Serrurier "+ data.nom +" ADC dépannage serrurerie urgence pas cher 24H/24 & 7J/7 dans le " + data.code_postal.replace("7500","").replace("750","") +"eme-Arrondissement ("+data.code_postal+") ✓ Ouverture de porte claquée fermée ou bloqué ✓ remplacement canons ✓ Coffre-Fort "

        }

    }

    else {
        var title =  'Serrurier '+data.nom+ ' ('+data.code_postal+') dépannage serrurerie urgence 24H/24'
        var description = "Serrurier "+data.nom +" ("+data.code_postal+") ADC dépannage serrurerie urgence pas cher 24H/24 & 7J/7 ✓ Ouverture de porte claquée fermée ou bloqué ✓ Coffre-Fort ✓ changement serrure & remplacement canons"
    }

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="description" content={description} />
                <meta name="og:description" content={description} />
                <meta property="og:image" content="https://adcentreprise.fr/assets/img/logo-adc-entreprise.webp" />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:type" content="website" />
                <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                          "@type": "Locksmith",
                          "name": "Serrurier ${data.nom} (${data.code_postal})",
                            "image":
                                    "https://adcentreprise.fr/assets/img/logo-adc-entreprise.webp"
                                   ,
                            "url": "https://adcentreprise.fr/${dep}/${ville}",
                                "telephone": "0972519300",
                        "paymentAccepted": [ "Espèces", "CB", "Chèques" ],
						"priceRange": "A partir  de 150 € HT",
						"currenciesAccepted":"EUR",
						"openingHours": "Du Lundi Au Dimanche",
						"openingHoursSpecification": [ {
							"@type": "OpeningHoursSpecification",
							"dayOfWeek": [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday",
								"Saturday",
								"Sunday"
							],
							"opens": "00:00",
							"closes": "23:59"
						} ],
						"areaServed": {
							"@type": "State",
							"name": "${data.departement}"
						},
                        "hasOfferCatalog": {
							"@type": "OfferCatalog",
							"name": "Serrurier ${data.nom}",
							"itemListElement": [
							  {
								"@type": "OfferCatalog",
								"name": "Ouverture de Porte",
								"itemListElement": [
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Ouverture de porte claquée"
									}
								  },
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Ouverture de porte bloquée"
									}
								  }
								]
							  },
							  {
								"@type": "OfferCatalog",
								"name": "Changement de Serrure",
								"itemListElement": [
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Changement de Serrure simple"
									}
								  },
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Remplacement de cylindre"
									}
								  },
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Changement de Serrure Multipoint"
									}
								  }
								]
							  },
							  {
								"@type": "OfferCatalog",
								"name": "Blindage de Porte",
								"itemListElement": [
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Installation de Porte Blindée"
									}
								  }
								]
							  },
							  {
								"@type": "OfferCatalog",
								"name": "Installation et réparation de rideau métallique",
								"itemListElement": [
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Réparation de rideau métallique"
									}
								  },
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Pose de rideau métallique"
									}
								  },
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Installation sur mesure de rideau métallique"
									}
								  }
								]
							  },
							  {
								"@type": "OfferCatalog",
								"name": "Ouverture de coffre-fort",
								"itemListElement": [
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Ouverture Coffre-fort A2P"
									}
								  },
								  {
									"@type": "Offer",
									"itemOffered": {
									  "@type": "Service",
									  "name": "Ouverture coffre-fort encastrable"
									}
								  }
								]
							  }
							]

						},
						"address": {
                                        "@type": "PostalAddress",
                                        "addressLocality": "${data.nom}",
                                        "addressRegion": "Ile-de-France",
                                        "postalCode": "${data.code_postal}",
                                        "addressCountry": "FR"
                                      },
                          "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "5",
                            "reviewCount": "${longueur}",
                            "itemReviewed": "https://adcentreprise.fr/${dep}/${ville}"
                          }
                        }
                      `}
                </script>
            </Helmet>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="/">Accueil / </a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href={"/"+dep+"/"}>Dépanneurs {data.departement} / </a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href={"/"+dep+"/"+ville+"/"}>Dépanneurs {data.nom} / </a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="#">{data.categorie.nom} / </a>
                    </li>
                </ul>
            </div>

            <section className="breadcrumb_main_area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="breadcrumb_main nice_title">
                            <img src="/assets/img/logo-adc-entreprise.webp" className="image-logo hidden-xs hidden-sm" alt="bonhomme"/>
                            <h1>Serruriers {data.nom} ({data.code_postal})</h1>
                            <span>A domicile pour résoudre tous vos problèmes</span>
                            <div className="row row-bouton">
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a target="_blank" href={"https://forms.adcentreprise.fr/devis?nompage=Serrurier "+data.nom}><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="another_facitilies_area" style={{"backgroundColor" : "#f1f6f8"}}>
                <div className="container">
                    <div className="section_title nice_title content-center">
                        <h3>Nos services</h3>
                    </div>
                    <div className="facilities_top_para">
                        <p style={{"margin" :"15px"}}>
                            {
                                parse(data.texte_categorie.Serrurier)
                            }
                        </p>
                    </div>
                </div>
            </section>

            <section className="about_us_area">
                <div className="container">
                    <div className="about_us clearfix">
                        <div className="section_title nice_title content-center">
                            <h3 style={{"marginBottom":"20px"}}>Comment nous contacter pour une intervention à {data.nom} ? </h3>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="showcase">
                            <div className="section_title margin-bottom-50 text-center">
                                <i className="numero">1</i>
                                <h5><strong>Contactez-nous par téléphone </strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details">
                                        <img src="/assets/img/comment-1.webp" alt="Comment contacter votre serrurier"/>
                                    </div>
                                    <div>
                                        <p className="texte-comment">
                                            Vous pouvez nous téléphoner 24h/24 et 7j/7 pour demander une intervention en urgence.
                                            Si ce n'est pas une urgence, merci de nous contacter entre 09h00 et 17h00.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="showcase">
                            <div className="section_title margin-bottom-50 text-center">
                                <i className="numero">2</i>
                                <h5><strong>Devis gratuit, demandez notre tarif</strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details">
                                        <img src="/assets/img/comment-2.webp" alt="comment-2"/>
                                    </div>
                                    <div>
                                        <p className="texte-comment">
                                            Intervention avec un prix fixe annoncé par téléphone pour éviter toutes
                                            mauvaises surpprises !
                                            Pour une demande particulière, un devis gratuit vous sera proposé.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="showcase">
                            <div className="section_title margin-bottom-50 text-center">
                                <i className="numero">3</i>
                                <h5><strong>Intervention rapide dans l'heure</strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details">
                                        <img src="/assets/img/comment-3.webp" alt="comment-3"/>
                                    </div>
                                    <div>
                                        <p className="texte-comment">Un dépanneur qualifié et expérimenté vous sera attribué
                                            pour un dépannage à domicile en moins de 1 heure !
                                            Notre artisan sera noté par vos soins sur sa prestation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-bouton">
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <a target="_blank" href={"https://forms.adcentreprise.fr/devis?nompage=Serrurier "+data.nom}><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about_us_area" itemScope itemType="https://schema.org/FAQPage">
                <div className="container">
                    <div className="about_us clearfix">
                        <div className="section_title nice_title content-center">
                            <h3>Foire aux questions</h3>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <div className="showcase"
                                 style={{'backgroundColor' : '#efe8b4', 'padding': '10px', 'borderRadius': '15px'}}>
                                <div className="section_title margin-bottom-50">
                                    <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>
                                        Pourquoi rechercher un serrurier à {data.nom} ({data.code_postal}) ?
                                    </strong></h5>
                                </div>
                                <div className="section_description">
                                    <div className="about_hotel">
                                        <div className="about_details" itemScope itemProp="acceptedAnswer"
                                             itemType="https://schema.org/Answer">
                                            <p itemProp="text">
                                            Votre serrurier à {data.nom} ({data.code_postal}) est à proximité de chez vous,
                                                il pourra réaliser une intervention de dépannage serrurerie en urgence plus rapidement en tant que serrurier local.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <div className="showcase"
                                 style={{'backgroundColor' : '#ecccdb', 'padding': '10px', 'borderRadius': '15px'}}>
                                <div className="section_title margin-bottom-50">
                                    <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>
                                        Combien coûte une ouverture de porte claquée par un Serrurier à {data.nom} ({data.code_postal}) ?
                                    </strong></h5>
                                </div>
                                <div className="section_description">
                                    <div className="about_hotel">
                                        <div className="about_details" itemScope itemProp="acceptedAnswer"
                                             itemType="https://schema.org/Answer">
                                            <p itemProp="text">
                                            Le tarif est régulièrement à partir de 120€ ou 150€ pour la plus part des entreprise de Serrurerie dépannage en urgence.
                                                Le Prix est pas cher mais peut varier selon l'horaire le soir ou le week-end.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <div className="showcase"
                                 style={{'backgroundColor' : '#b2d6ee', 'padding': '10px', 'borderRadius': '15px'}}>
                                <div className="section_title margin-bottom-50">
                                    <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>
                                        Comment trouver un serrurier pas cher à {data.nom} ({data.code_postal}) ?
                                    </strong></h5>
                                </div>
                                <div className="section_description">
                                    <div className="about_hotel">
                                        <div className="about_details" itemScope itemProp="acceptedAnswer"
                                             itemType="https://schema.org/Answer">
                                            <p itemProp="text">
                                            Si vous recherchez un Serrurier à prix pas cher pour un dépannage serrurerie ou un changement de serrure de sécurité,
                                                nous vous conseillons de choisir un serrurier de votre localité dans votre ville à côté de chez vous qui vous coûtera toujours moins cher.                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <div className="showcase"
                                 style={{'backgroundColor' : '#ddf7dc', 'padding': '10px', 'borderRadius': '15px'}}>
                                <div className="section_title margin-bottom-50">
                                    <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>
                                        Ma clé se bloque dans ma serrure et il est difficile de la déverrouiller, que faire ?
                                    </strong>
                                    </h5>
                                </div>
                                <div className="section_description">
                                    <div className="about_hotel">
                                        <div className="about_details" itemScope itemProp="acceptedAnswer"
                                             itemType="https://schema.org/Answer">
                                            <p itemProp="text">
                                            Notre conseil est de faire vérifier votre serrure par un serrurier professionnel pour savoir s'il est possible
                                                de la réparer ou s'il vaut mieux la remplacer.
                                                Cela vous évitera de vous retrouver coincé devant votre porte en rentrant chez vous.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-bouton hidden-xs">
                <a href="https://wa.link/h0r9kh" target="_blank" rel="noreferrer"><img src="/assets/img/img-whatsapp.webp" alt="Whatsapp" />
                </a>
            </section>
            <section className="container-bouton visible-xs">
                <a href="https://wa.link/h0r9kh" target="_blank" rel="noreferrer"><img src="/assets/img/adc-whatsapp.webp" alt="Whatsapp" />
                </a>
            </section>


            <section className="about_us_area">
                <div className="container">
                    <div className="row">
                        <div className="select_room clearfix">
                            <div className="section_title nice_title content-center">
                                <h3>Services de serrurerie</h3>
                            </div>
                            <div className="facilities_top_para text-center">
                                <p style={{"margin" :"25px"}}>
                                    Ci-dessous vous sont représentés tous les services proposés par les meilleurs entreprises de dépannages serrurerie en urgence à domicile sur la ville de {data.nom} ({data.code_postal}). Ils seront disponible pour vous dépanner en urgence 7J/7 & 24H/24 à la maison rapidement et efficacement car ce sont de véritables professionnels du métier. La liste ci-dessous va augmenter pour vous offrir un répertoire de services en serrurerie toujours plus fourni et ainsi vous mettre à disposition une solution complète pour vous dépanner à la maison.
                                </p>
                            </div>

                            <div className="select_room_content clearfix">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/ouverture-de-porte.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Ouverture de porte</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/coffre-fort.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Ouverture de coffre fort</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/changement-de-serrure.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Changement de serrure</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/porte-blindee.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Porte blindée</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="select_room_area">
                <div className="container">
                    <div className="about_us clearfix">
                        <div className="section_title nice_title content-center">
                            <h3>Marques de serrures</h3>
                        </div>
                    </div>
                    <div className="row marque">
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrure-fichet.webp" alt={"Serrure Fichet " + data.nom}/>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-anker.webp" alt={"Serrurier Anker " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-bricard.webp" alt={"Serrurier Bricard " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-cavith.webp" alt={"Serrurier Cavith " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-cyc.webp" alt={"Serrurier CYC " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-heracles.webp" alt={"Serrurier Heracles " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-jpm.webp" alt={"Serrurier JPM " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-kaba.webp" alt={"Serrurier Kaba " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-keso.webp" alt={"Serrurier Keso " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-medeco.webp" alt={"Serrurier Medeco " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-mottura.webp" alt={"Serrurier Mottura " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-muel.webp" alt={"Serrurier Muel " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-mul-t-lock.webp" alt={"Serrurier Mul-T-Lock " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-picard.webp" alt={"Serrurier Picard " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-pollux.webp" alt={"Serrurier Pollux " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-reelax.webp" alt={"Serrurier Reelax " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-vachette.webp" alt={"Serrurier Vachette " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-vak.webp" alt={"Serrurier Vak " + data.nom} />
                        </div>
                    </div>
                </div>
            </section>

            { data.top3.length > 0 ?
                <section className="about_us_area">
                    <div className="row">
                        <div className="facilities_name clearfix ">
                            <div className="col-lg-12 col-md-12" style={{"marginBottom": "30px"}}>
                                <div className="section_title nice_title content-center">
                                    <h3>Serruriers à {data.nom}</h3>
                                    <p>Liste informative et aléatoire: certaines entreprises ne font pas partie du réseau de partenaire ADC Entreprise</p>
                                </div>
                            </div>
                            <div className="top3">
                                { data.top3.map((top) => (
                                    <div className="row addr" >
                                        <div className="col-lg-6 col-md-6">
                                            <span>{top.name}</span>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <span>{top.adresse}</span>
                                        </div>

                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>

                </section>
                : <div></div>
            }

            <section className="review">
                <div className="section_content">
                    <div className="showcase">
                        <div className="section_description container">
                            <div className="row" style={{"marginRight":"0px", "marginLeft":"0px"}}>
                                <div className="facilities_name clearfix margin-bottom-40 ">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="section_title nice_title content-center">
                                            <h3>AVIS</h3>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button data-page={tablename} data-nompage={data.nom} data-tally-open="3yE7X3" data-tally-layout="modal" data-tally-align-left="1" data-tally-emoji-text="👋" data-tally-emoji-animation="wave" className="btn btn-primary" data-tally-width="600">Donner votre avis</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="testimonials-grid">
                    { dataAvis.records.map((avis, indexAvis) => (

                        <div className="testimonials-box" id={"Avis"+indexAvis}>
                            <div className="inside">
                                <div className="testimonials-box-title"> <Moment format="DD/MM/YYYY" parse="YYYY-MM-DD">{avis.fields.Date} </Moment></div>
                                <div className="etoile">{
                                    [...Array(avis.fields.Note)].map((elementInArray, index) => (
                                        <i className="fa fa-star"></i>))
                                }
                                </div>
                                <div className="testimonials-box-text"> {avis.fields.Avis}</div>
                                <div
                                    className="testimonials-box-username"> {avis.fields.Nom}
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
                <div className="pagination">
                    <button className="btn btn-primary" onClick={Pagination}>1</button>
                    {dataAvis.records.length>6 ? <button className="btn btn-primary boutonpage  " onClick={Pagination1}>2</button> : <button className="vide"></button>}
                    {dataAvis.records.length>12 ? <button className="btn btn-primary boutonpage  " onClick={Pagination2}>3</button> : <button className="vide"></button>}
                </div>
            </div>

            <div className="contact_us_area content-left">
                <div className="container">
                    <div className="contact_us clearfix">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="call clearfix">
                                <h6>Téléphone</h6>
                                <p><a href="tel:0972519300">09.72.51.93.00</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="email_us clearfix">
                                <h6>Email</h6>
                                <p>contact@adcentreprise.fr</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="social_icons clearfix">
                                <ul>
                                    <li><a href="https://www.facebook.com/ADCentrerpise"><i className="fa fa-facebook"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer_area">
                <div className="container">
                    <div className="footer">
                        <div className="row">
                            <div className="container">
                                <div className="footer_copyright margin-tb-30 content-center hidden-xs">
                                    <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <a href="/mentions-legales/">Mentions
                                    légales</a> - <a href="/condition-d-utilisation/">Condition d'utilisation</a> - <a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                                </div>
                                <div className="footer_copyright margin-tb-30 content-center visible-xs">
                                    <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <br /><a href="/mentions-legales/">Mentions
                                    légales</a> <br /><a href="/condition-d-utilisation/">Condition d'utilisation</a> <br /><a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default VilleCategorie;