import { useParams } from "react-router";
import React,{useState,useEffect} from 'react';
import {Helmet} from "react-helmet";

function Categorie() {
    const [data,setData]=useState([{"nom":"texte-bidon"}]);
    const [texte1, setTexte1] = useState('');
    const [texte2, setTexte2] = useState('');

    useEffect(()=>{
        function getData(){
            fetch(filename
                ,{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function(response){
                    return response.json();

                })

                .then(function(myJson) {
                    setData(myJson)
                });

            fetch("/texte/serrurier/1.txt"
                ,{
                    headers : {
                        'Content-Type': 'text/plain',
                        'Accept': 'text/plain'
                    }
                }
            )
                .then(function(response){
                    return response.text();

                })

                .then(function(myJson) {
                    setTexte1(myJson)
                });

            fetch("/texte/serrurier/2.txt"
                ,{
                    headers : {
                        'Content-Type': 'text/plain',
                        'Accept': 'text/plain'
                    }
                }
            )
                .then(function(response){
                    return response.text();

                })

                .then(function(myJson) {
                    setTexte2(myJson)
                });

        }

        getData();

    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    let { id } = useParams();
    var filename = '/'+ id+ '.json';
    return (
    <div>
        <Helmet>
            <title>Les meilleurs serruriers d'urgence à domicile à Paris & Banlieues Parisiennes</title>
            <meta name="title" content="Les meilleurs serruriers d'urgence à domicile à Paris & Banlieues Parisiennes" />
            <meta name="description" content="Les meilleurs dépanneurs serruriers à Paris & Banlieues Parisiennes. ✓ Ouverture de porte claquée fermée ou bloqué ✓ remplacement canons ✓ Coffre-Fort" />
        </Helmet>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                    <a className="nav-link" href="/">Accueil / </a>
                </li>
                <li className="nav-item active">
                    <a className="nav-link" href="#">Serrurier / </a>
                </li>
            </ul>
        </div>
        <section className="breadcrumb_main_area">
            <div className="container-fluid">
                <div className="row">
                    <div className="breadcrumb_main nice_title">
                        <img src="/assets/img/logo-adc-entreprise.webp" className="image-logo hidden-xs" alt="bonhomme"/>
                        <h1>Les meilleurs {id}s</h1>
                        <span>A domicile pour résoudre tous vos problèmes</span>
                        <div className="row row-bouton">
                            <div className="col-md-6 col-lg-6 col-xs-12">
                                <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xs-12">
                                <a target="_blank" href={"https://forms.adcentreprise.fr/devis?nompage=Serrurier"}><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="another_facitilies_area">
            <div className="container">
                <div className="facilities_top_para">
                    <p style={{"margin" :"15px"}}>
                        {data[0].texte}
                    </p>
                </div>
            </div>
        </section>

        <section className="select_room_area">
            <div className="container">
                <div className="row">
                    <div className="select_room clearfix">
                        <div className="section_title nice_title content-center">
                            <h3>Serruriers en Île de France</h3>
                        </div>
                        <div className="facilities_top_para text-center">
                            <p style={{"margin" :"15px"}}>
                                {texte2}
                            </p>
                        </div>

                        <div className="select_room_content clearfix">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                <div className="room_thumb small_room_thumb margin-bottom-35">
                                    <a href={"/paris/categorie-"+id+"/"}><img src="/assets/img/departement/paris.webp" alt=""/></a>
                                    <div className="room_details">
                                        <div className="about_room floatleft">
                                            <div className="room_quality floatleft">
                                                <h5>Paris</h5>
                                            </div>
                                        </div>
                                        <div className="room_block floatright">
                                            <a href={"/paris/categorie-"+id+"/"} className="btn btn-black">Explorer</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="room_thumb small_room_thumb">
                                    <a href={"/hauts-de-seine/categorie-"+id+"/"}><img src="/assets/img/departement/hauts-de-seine.webp" alt=""/></a>
                                    <div className="room_details">
                                        <div className="about_room floatleft">
                                            <div className="room_quality floatleft">
                                                <h5>Hauts-de-Seine</h5>
                                            </div>
                                        </div>
                                        <div className="room_block floatright">
                                            <a href={"/hauts-de-seine/categorie-"+id+"/"} className="btn btn-black">Explorer</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                <div className="room_thumb small_room_thumb margin-bottom-35">
                                    <a href={"/seine-saint-denis/categorie-"+id+"/"}><img src="/assets/img/departement/seine-saint-denis.webp"
                                                                                   alt=""/></a>
                                    <div className="room_details">
                                        <div className="about_room floatleft">
                                            <div className="room_quality floatleft">
                                                <h5>Seine-Saint-Denis</h5>
                                            </div>
                                        </div>
                                        <div className="room_block floatright">
                                            <a href={"/seine-saint-denis/categorie-"+id+"/"} className="btn btn-black">Explorer</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="room_thumb small_room_thumb">
                                    <a href={"/seine-et-marne/categorie-"+id+"/"}><img src="/assets/img/departement/seine-et-marne.webp" alt=""/></a>
                                    <div className="room_details">
                                        <div className="about_room floatleft">
                                            <div className="room_quality floatleft">
                                                <h5>Seine-et-Marne</h5>
                                            </div>
                                        </div>
                                        <div className="room_block floatright">
                                            <a href={"/seine-et-marne/categorie-"+id+"/"} className="btn btn-black">Explorer</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                <div className="room_thumb small_room_thumb margin-bottom-35">
                                    <a href={"/essonne/categorie-"+id+"/"}><img src="/assets/img/departement/essonne.webp" alt=""/></a>
                                    <div className="room_details">
                                        <div className="about_room floatleft">
                                            <div className="room_quality floatleft">
                                                <h5>Essonne</h5>
                                            </div>
                                        </div>
                                        <div className="room_block floatright">
                                            <a href={"/essonne/categorie-"+id+"/"} className="btn btn-black">Explorer</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="room_thumb small_room_thumb">
                                    <a href={"/yvelines/categorie-"+id+"/"}><img src="/assets/img/departement/yvelines.webp" alt=""/></a>
                                    <div className="room_details">
                                        <div className="about_room floatleft">
                                            <div className="room_quality floatleft">
                                                <h5>Yvelines</h5>
                                            </div>
                                        </div>
                                        <div className="room_block floatright">
                                            <a href={"/yvelines/categorie-"+id+"/"} className="btn btn-black">Explorer</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                <div className="room_thumb small_room_thumb margin-bottom-35">
                                    <a href={"/val-de-marne/categorie-"+id+"/"}><img src="/assets/img/departement/val-de-marne.webp" alt=""/></a>
                                    <div className="room_details">
                                        <div className="about_room floatleft">
                                            <div className="room_quality floatleft">
                                                <h5>Val-de-Marne</h5>
                                            </div>
                                        </div>
                                        <div className="room_block floatright">
                                            <a href={"/val-de-marne/categorie-"+id+"/"} className="btn btn-black">Explorer</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="room_thumb small_room_thumb">
                                    <a href={"/val-doise/categorie-"+id+"/"}><img src="/assets/img/departement/val-doise.webp" alt=""/></a>
                                    <div className="room_details">
                                        <div className="about_room floatleft">
                                            <div className="room_quality floatleft">
                                                <h5>Val-d'Oise</h5>
                                            </div>
                                        </div>
                                        <div className="room_block floatright">
                                            <a href={"/val-doise/categorie-"+id+"/"} className="btn btn-black">Explorer</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="about_us_area">
            <div className="container">
                <div className="about_us clearfix">
                    <div className="section_title nice_title content-center">
                        <h3 style={{"marginBottom":"20px"}}>Comment nous contacter pour une intervention ? </h3>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="showcase">
                        <div className="section_title margin-bottom-50 text-center">
                            <i className="numero">1</i>
                            <h5><strong>Contactez-nous par téléphone </strong></h5>
                        </div>
                        <div className="section_description">
                            <div className="about_hotel">
                                <div className="about_details">
                                    <img src="/assets/img/comment-1.webp" alt="Comment-1"/>
                                </div>
                                <div>
                                    <p className="texte-comment">
                                        Vous pouvez nous téléphoner 24h/24 et 7j/7 pour demander une intervention en urgence.
                                        Si ce n'est pas une urgence, merci de nous contacter entre 09h00 et 17h00.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="showcase">
                        <div className="section_title margin-bottom-50 text-center">
                            <i className="numero">2</i>
                            <h5><strong>Devis gratuit, demandez notre tarif</strong></h5>
                        </div>
                        <div className="section_description">
                            <div className="about_hotel">
                                <div className="about_details">
                                    <img src="/assets/img/comment-2.webp" alt="comment-2"/>
                                </div>
                                <div>
                                    <p className="texte-comment">
                                        Intervention avec un prix fixe annoncé par téléphone pour éviter toutes
                                        mauvaises surpprises !
                                        Pour une demande particulière, un devis gratuit vous sera proposé.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="showcase">
                        <div className="section_title margin-bottom-50 text-center">
                            <i className="numero">3</i>
                            <h5><strong>Intervention rapide dans l'heure</strong></h5>
                        </div>
                        <div className="section_description">
                            <div className="about_hotel">
                                <div className="about_details">
                                    <img src="/assets/img/comment-3.webp" alt="comment-3"/>
                                </div>
                                <div>
                                    <p className="texte-comment">Un dépanneur qualifié et expérimenté vous sera attribué
                                        pour un dépannage à domicile en moins de 1 heure !
                                        Notre artisan sera noté par vos soins sur sa prestation.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-bouton">
                    <div className="col-md-6 col-lg-6 col-xs-12">
                        <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xs-12">
                        <a target="_blank" href="https://forms.adcentreprise.fr/devis"><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                    </div>
                </div>
            </div>
        </section>

        <section className="about_us_area" itemScope itemType="https://schema.org/FAQPage">
            <div className="container">
                <div className="about_us clearfix">
                    <div className="section_title nice_title content-center">
                        <h3>Foire aux questions</h3>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className="showcase"
                             style={{'backgroundColor' : '#efe8b4', 'padding': '10px', 'borderRadius': '15px'}}>
                            <div className="section_title margin-bottom-50">
                                <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>
                                    Pourquoi rechercher un serrurier ?
                                </strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details" itemScope itemProp="acceptedAnswer"
                                         itemType="https://schema.org/Answer">
                                        <p itemProp="text">
                                            Votre serrurier est à proximité de chez vous,
                                            il pourra réaliser une intervention de dépannage serrurerie en urgence plus rapidement en tant que serrurier local.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className="showcase"
                             style={{'backgroundColor' : '#ecccdb', 'padding': '10px', 'borderRadius': '15px'}}>
                            <div className="section_title margin-bottom-50">
                                <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>
                                    Combien coûte une ouverture de porte claquée par un Serrurier ?
                                </strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details" itemScope itemProp="acceptedAnswer"
                                         itemType="https://schema.org/Answer">
                                        <p itemProp="text">
                                            Le tarif est régulièrement à partir de 120€ ou 150€ pour la plus part des entreprise de Serrurerie dépannage en urgence.
                                            Le Prix est pas cher mais peut varier selon l'horaire le soir ou le week-end.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className="showcase"
                             style={{'backgroundColor' : '#b2d6ee', 'padding': '10px', 'borderRadius': '15px'}}>
                            <div className="section_title margin-bottom-50">
                                <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>
                                    Comment trouver un serrurier pas cher ?
                                </strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details" itemScope itemProp="acceptedAnswer"
                                         itemType="https://schema.org/Answer">
                                        <p itemProp="text">
                                            Si vous recherchez un Serrurier à prix pas cher pour un dépannage serrurerie ou un changement de serrure de sécurité,
                                            nous vous conseillons de choisir un serrurier de votre localité dans votre ville à côté de chez vous qui vous coûtera toujours moins cher.                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className="showcase"
                             style={{'backgroundColor' : '#ddf7dc', 'padding': '10px', 'borderRadius': '15px'}}>
                            <div className="section_title margin-bottom-50">
                                <h5 style={{'textTransform': 'none'}} itemProp="name"><strong>
                                    Ma clé se bloque dans ma serrure et il est difficile de la déverrouiller, que faire ?
                                </strong>
                                </h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details" itemScope itemProp="acceptedAnswer"
                                         itemType="https://schema.org/Answer">
                                        <p itemProp="text">
                                            Notre conseil est de faire vérifier votre serrure par un serrurier professionnel pour savoir s'il est possible
                                            de la réparer ou s'il vaut mieux la remplacer.
                                            Cela vous évitera de vous retrouver coincé devant votre porte en rentrant chez vous.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="container-bouton hidden-xs">
            <a href="https://wa.link/h0r9kh" target="_blank" rel="noreferrer"><img src="/assets/img/img-whatsapp.webp" alt="Whatsapp" />
            </a>
        </section>
        <section className="container-bouton visible-xs">
            <a href="https://wa.link/h0r9kh" target="_blank" rel="noreferrer"><img src="/assets/img/adc-whatsapp.webp" alt="Whatsapp" />
            </a>
        </section>

        <section className="select_room_area">
            <div className="container">
                <div className="row">
                    <div className="select_room clearfix">
                        <div className="section_title nice_title content-center">
                            <h3>Services de Serrurerie</h3>
                        </div>
                        <div className="facilities_top_para text-center">
                            <p>
                                {texte1}
                            </p>
                        </div>

                        <div className="select_room_content clearfix">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                <div className="room_thumb small_room_thumb margin-bottom-35">
                                    <img src="/assets/img/ouverture-de-porte.webp" alt=""/>
                                    <div className="room_details">
                                        <div className="about_room">
                                            <div className="room_quality text-center">
                                                <h5>Ouverture de porte</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                <div className="room_thumb small_room_thumb margin-bottom-35">
                                    <img src="/assets/img/coffre-fort.webp" alt=""/>
                                    <div className="room_details">
                                        <div className="about_room">
                                            <div className="room_quality text-center">
                                                <h5>Ouverture de coffre fort</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                <div className="room_thumb small_room_thumb margin-bottom-35">
                                    <img src="/assets/img/changement-de-serrure.webp" alt=""/>
                                    <div className="room_details">
                                        <div className="about_room">
                                            <div className="room_quality text-center">
                                                <h5>Changement de serrure</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                <div className="room_thumb small_room_thumb margin-bottom-35">
                                    <img src="/assets/img/porte-blindee.webp" alt=""/>
                                    <div className="room_details">
                                        <div className="about_room">
                                            <div className="room_quality text-center">
                                                <h5>Porte blindée</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="select_room_area">
            <div className="container">
                <div className="about_us clearfix">
                    <div className="section_title nice_title content-center">
                        <h3>Marques de serrures</h3>
                    </div>
                </div>
                <div className="row marque">
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrure-fichet.png" alt={"Serrure Fichet " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-anker.png" alt={"Serrurier Anker " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-bricard.png" alt={"Serrurier Bricard " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-cavith.jpg" alt={"Serrurier Cavith " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-cyc.png" alt={"Serrurier CYC " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-heracles.png" alt={"Serrurier Heracles " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-jpm.jpg" alt={"Serrurier JPM " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-kaba.jpg" alt={"Serrurier Kaba " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-keso.jpg" alt={"Serrurier Keso " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-medeco.jpg" alt={"Serrurier Medeco " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-mottura.jpg" alt={"Serrurier Mottura " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-muel.png.jpg" alt={"Serrurier Muel " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-mul-t-lock.jpg" alt={"Serrurier Mul-T-Lock " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-picard.jpg" alt={"Serrurier Picard " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-pollux.jpg" alt={"Serrurier Pollux " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-reelax.png" alt={"Serrurier Reelax " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-vachette.png" alt={"Serrurier Vachette " } />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="/assets/img/marque/serrurier-vak.png" alt={"Serrurier Vak " } />
                    </div>
                </div>
            </div>
        </section>

        <div className="contact_us_area content-left">
            <div className="container">
                <div className="contact_us clearfix">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="call clearfix">
                            <h6>Téléphone</h6>
                            <p><a href="tel:0972519300">09.72.51.93.00</a></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="email_us clearfix">
                            <h6>Email</h6>
                            <p>contact@adcentreprise.fr</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="social_icons clearfix">
                            <ul>
                                <li><a href="https://www.facebook.com/ADCentrerpise"><i className="fa fa-facebook"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer className="footer_area">
            <div className="container">
                <div className="footer">
                    <div className="row">
                        <div className="container">
                            <div className="footer_copyright margin-tb-30 content-center hidden-xs">
                                <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <a href="/mentions-legales/">Mentions
                                légales</a> - <a href="/condition-d-utilisation/">Condition d'utilisation</a> - <a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                            </div>
                            <div className="footer_copyright margin-tb-30 content-center visible-xs">
                                <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <br /><a href="/mentions-legales/">Mentions
                                légales</a> <br /><a href="/condition-d-utilisation/">Condition d'utilisation</a> <br /><a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    )
        ;
}

export default Categorie;