import React, {useEffect, useState} from "react";
import { useParams } from "react-router";
import "./Departements-categorie.css"
import {Helmet} from "react-helmet";
import parse from 'html-react-parser'


function DepartementCategorie() {
    const [data,setData]=useState({"nom":"", "preposition":'', "liste_ville": [], "texte": "", "texte_categorie": {
            "Serrurier": ""
        }});
    const [data2,setData2]=useState({"nom":"", "texte": ""});

    useEffect(()=>{
        function getData(){
            fetch(filename
                ,{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function(response){
                    return response.json();

                })

                .then(function(myJson) {
                    setData(myJson)
                });

            fetch(filename2
                ,{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function(response){
                    return response.json();

                })

                .then(function(myJson) {
                    setData2(myJson)
                });

        }

        getData();

    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    let { dep, categorie } = useParams();
    var filename = '/json/'+ dep+'/data.json';
    var filename2 = '/json/serrurier/'+ dep+'/data.json';

    var title =  'Serrurier '+data.nom+ ' ('+data.code+') dépannage serrurerie urgence pas cher 24H/24 ADC'
    var description = "Serrurier "+data.nom +" ("+data.code+") ADC dépannage serrurerie urgence pas cher 24H/24 & 7J/7 ✓ Ouverture de porte claquée, fermé ou bloqué ✓ changement de serrure, canons ✓ Devis Gratuit Blindage"

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="/">Accueil / </a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href={"/"+dep+"/"}>Dépanneurs {data.nom} / </a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="#">Serruriers / </a>
                    </li>
                </ul>
            </div>
            <section className="breadcrumb_main_area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="breadcrumb_main nice_title">
                            <img src="/assets/img/logo-adc-entreprise.webp" className="image-logo hidden-xs" alt="bonhomme"/>
                            <h1>Vos { categorie}s {data.preposition} {data.nom}</h1>
                            <span>A domicile pour résoudre tous vos problèmes</span>
                            <div className="row row-bouton">
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a href="tel:0972519300"><button className="bouton bouton-telephone">09.72.51.93.00</button></a>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <a target="_blank" href={"https://forms.adcentreprise.fr/devis?nompage=Serrurier "+data.nom}><button className="bouton bouton-devis">Devis Gratuit !</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="another_facitilies_area">
                <div className="container">
                    <div className="section_title nice_title content-center">
                        <h3>Nos services</h3>
                    </div>
                    <div className="facilities_top_para">
                        <p>
                            {parse(data2.texte)}
                            </p>
                    </div>
                </div>
            </section>

            <section className="select_room_area ">
                <div className="container">
                    <div className="row">
                        <div className="select_room clearfix">
                            <div className="section_title nice_title content-center">
                                <h3 className="capitalize">{ categorie }s {data.preposition} {data.nom}</h3>
                            </div>
                            <div className="row">
                                { data.liste_ville.map((ville) => (
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <a href={"/"+dep+"/"+ville.url+"/serrurier/"} className="ville-liste"> <h5 className="capitalize">{ categorie }s {ville.nom}</h5></a>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about_us_area">
                <div className="container">
                    <div className="about_us clearfix">
                        <div className="section_title nice_title content-center">
                            <h3 style={{"margin-bottom":"20px"}}>Comment nous contacter ? </h3>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="showcase">
                            <div className="section_title margin-bottom-50 text-center">
                                <i className="numero">1</i>
                                <h5><strong>Contactez-nous par téléphone </strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details">
                                        <img src="/assets/img/comment-1.webp" alt="Comment-1"/>
                                    </div>
                                    <div>
                                        <p className="texte-comment">
                                            Vous pouvez nous téléphoner 24h/24 et 7j/7 pour demander une intervention en urgence.
                                            Si ce n'est pas une urgence, merci de nous contacter entre 09h00 et 17h00.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="showcase">
                            <div className="section_title margin-bottom-50 text-center">
                                <i className="numero">2</i>
                                <h5><strong>Devis gratuit, demandez notre tarif</strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details">
                                        <img src="/assets/img/comment-2.webp" alt="comment-2"/>
                                    </div>
                                    <div>
                                        <p className="texte-comment">
                                            Intervention avec un prix fixe annoncé par téléphone pour éviter toutes
                                            mauvaises surpprises !
                                            Pour une demande particulière, un devis gratuit vous sera proposé.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="showcase">
                            <div className="section_title margin-bottom-50 text-center">
                                <i className="numero">3</i>
                                <h5><strong>Intervention rapide dans l'heure</strong></h5>
                            </div>
                            <div className="section_description">
                                <div className="about_hotel">
                                    <div className="about_details">
                                        <img src="/assets/img/comment-3.webp" alt="comment-3"/>
                                    </div>
                                    <div>
                                        <p className="texte-comment">Un dépanneur qualifié et expérimenté vous sera attribué
                                            pour un dépannage à domicile en moins de 1 heure !
                                            Notre artisan sera noté par vos soins sur sa prestation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about_us_area">
                <div className="container">
                    <div className="row">
                        <div className="select_room clearfix">
                            <div className="section_title nice_title content-center">
                                <h3>Services de serrurerie</h3>
                            </div>
                            <div className="facilities_top_para text-center">
                                <p style={{"margin" :"25px"}}>
                                    Ci-dessous vous sont représentés tous les services proposés par les meilleurs entreprises de dépannages serrurerie en urgence à domicile {data.preposition} {data.nom} ({data.code}). Ils seront disponible pour vous dépanner en urgence 7J/7 & 24H/24 à la maison rapidement et efficacement car ce sont de véritables professionnels du métier. La liste ci-dessous va augmenter pour vous offrir un répertoire de services en serrurerie toujours plus fourni et ainsi vous mettre à disposition une solution complète pour vous dépanner à la maison.                                </p>
                            </div>

                            <div className="select_room_content clearfix">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/ouverture-de-porte.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Ouverture de porte</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/coffre-fort.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Ouverture de coffre fort</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/changement-de-serrure.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Changement de serrure</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-sx-12">
                                    <div className="room_thumb small_room_thumb margin-bottom-35">
                                        <img src="/assets/img/porte-blindee.webp" alt=""/>
                                        <div className="room_details">
                                            <div className="about_room">
                                                <div className="room_quality text-center">
                                                    <h5>Porte blindée</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="select_room_area">
                <div className="container">
                    <div className="about_us clearfix">
                        <div className="section_title nice_title content-center">
                            <h3>Marques de serrures</h3>
                        </div>
                    </div>
                    <div className="row marque">
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrure-fichet.png" alt={"Serrure Fichet " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-anker.png" alt={"Serrurier Anker " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-bricard.png" alt={"Serrurier Bricard " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-cavith.jpg" alt={"Serrurier Cavith " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-cyc.png" alt={"Serrurier CYC " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-heracles.png" alt={"Serrurier Heracles " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-jpm.jpg" alt={"Serrurier JPM " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-kaba.jpg" alt={"Serrurier Kaba " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-keso.jpg" alt={"Serrurier Keso " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-medeco.jpg" alt={"Serrurier Medeco " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-mottura.jpg" alt={"Serrurier Mottura " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-muel.png.jpg" alt={"Serrurier Muel " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-mul-t-lock.jpg" alt={"Serrurier Mul-T-Lock " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-picard.jpg" alt={"Serrurier Picard " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-pollux.jpg" alt={"Serrurier Pollux " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-reelax.png" alt={"Serrurier Reelax " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-vachette.png" alt={"Serrurier Vachette " + data.nom} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="/assets/img/marque/serrurier-vak.png" alt={"Serrurier Vak " + data.nom} />
                        </div>
                    </div>
                </div>
            </section>

            <div className="contact_us_area content-left">
                <div className="container">
                    <div className="contact_us clearfix">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="call clearfix">
                                <h6>Téléphone</h6>
                                <p><a href="tel:0972519300">09.72.51.93.00</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="email_us clearfix">
                                <h6>Email</h6>
                                <p>contact@adcentreprise.fr</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="social_icons clearfix">
                                <ul>
                                    <li><a target="_blank" href="https://www.facebook.com/ADCentrerpise"><i className="fa fa-facebook"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer_area">
                <div className="container">
                    <div className="footer">
                        <div className="row">
                            <div className="container">
                                <div className="footer_copyright margin-tb-30 content-center hidden-xs">
                                    <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <a href="/mentions-legales/">Mentions
                                    légales</a> - <a href="/condition-d-utilisation/">Condition d'utilisation</a> - <a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                                </div>
                                <div className="footer_copyright margin-tb-30 content-center visible-xs">
                                    <p className="text-center">Tous droits réservés à la société <a href="/"> Cyber Web Marketing</a> © 2022. </p> <br /><a href="/mentions-legales/">Mentions
                                    légales</a> <br /><a href="/condition-d-utilisation/">Condition d'utilisation</a> <br /><a href="/politique-de-confidentialite/">Politique de confidentialité</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>

    );
}

export default DepartementCategorie;